<template>
    <div>
        <div class="header">
            <van-nav-bar :title="$t('用户行为守则')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="maincontent">
            <template v-if="lang == 'zh'">
                <pre>
    AFOUR用户同意遵守以下行为规则,以确保AFOUR平台不 被用于破坏或操纵目的。否则,禁止任何扰乱或操纵数字资 产交易的交易活动。下面的列表并非排他性的,旨在提供有 关某些禁止活动的说明。

在您使用服务、您的帐户时,或在您与我们、其他用户或第 三方互动的过程中:

1:不违反任何法律、法规、条例或法规(例如,有关金融服 务、消费者保护、不公平竞争、反歧视或虚假广告的法律、 法规、条例或法规);

2:请勿持有或交易我们合理认为具有潜在欺诈性的数字资 产;

3:请勿参与潜在的欺诈或可疑活动和/或交易;

4:请勿采取任何对我们或代表我们运营的网站、软件、系统 (包括用于提供任何服务的任何网络和服务器)或服务造成 不合理或不成比例的巨大负载的行为;

5:请勿助长任何病毒、特洛伊木马、恶意软件、蠕虫或其他 计算机编程例程,这些程序试图或可能损坏、破坏、损坏、 误用、有害干扰、秘密拦截或侵占,或未经授权访问任何系 统、数据、信息或服务;

6:请勿以产生不必要的波动或创造价格不反映或不会反映公 平市场价值的条件为目的而下订单。这可能包括人为地以相 同的价格买入和卖出以人为地增加交易量,分割订单以创建 更多的交易执行和更多的交易活动,或者使用连续的交易执 行来显示特定方向的人为动量。

7:请勿以在执行前取消买价或卖价为唯一目的而下订单。

8:请勿出于人为执行的目的与他人预先安排任何订单。

9:请勿以协助他人进行交易为目的下订单。

10:请勿同时下达可能相互执行的买卖订单。
                </pre>
            </template>
            <template v-else>
                <pre>
    AFOUR users agree to abide by the following rules of conduct in order to ensure that the AFOUR platform is not used for disruptive or manipulative purposes. Otherwise, any trading activity that disrupts or manipulates the trading of digital assets is prohibited. The following list is not exclusive and is intended to provide clarification regarding certain prohibited activities.

When you use the Services, your account, or in the course of your interactions with us, other users, or third parties.

1: Do not violate any law, rule, regulation or statute (e.g., laws, rules, regulations or statutes relating to financial services, consumer protection, unfair competition, anti-discrimination, or false advertising).

2: Do not hold or trade in digital assets that we reasonably believe to be potentially fraudulent.

3: Do not engage in potentially fraudulent or suspicious activities and/or transactions; and

4: Do not take any action that imposes an unreasonable or disproportionately large load on our websites, software, systems (including any networks and servers used to provide any of the Services) or the Services operated by us or on our behalf; and

5: Do not promote any viruses, Trojan horses, malware, worms or other computer programming routines that attempt to or may damage, disrupt, corrupt, misuse, detrimentally interfere with, surreptitiously intercept or expropriate, or gain unauthorized access to, any systems, data, information or services; and

6: Do not place orders for the purpose of creating unnecessary volatility or creating conditions in which prices do not or will not reflect fair market value. This may include artificially buying and selling at the same price to artificially increase trading volume, splitting orders to create more executions and more trading activity, or using consecutive executions to show artificial momentum in a particular direction.

7: Do not place an order with the sole purpose of canceling the bid or ask price prior to execution.

8: Do not prearrange any order with another person for the purpose of artificial execution.

9: Do not place an order for the purpose of assisting another person to trade.

10:Do not place simultaneous buy and sell orders that may be executed against each other.
                </pre>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lang: localStorage.getItem('lang') || 'en',
        };
    },
    created() {
    },
    methods: {

    }
};
</script>
<style lang="less" scoped>
.maincontent {
    padding-top: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 0.93333rem;
    background: #f7f7f7;
    height: 100vh;

    pre {
        // 超出换行
        white-space: pre-wrap;
        word-wrap: break-word;
        // 字体如何向左对齐呢？
        text-align: justify;
        text-justify: inter-ideograph;
        // 字体颜色
        color: #333;
        padding: 15px;
    }

}
</style>
